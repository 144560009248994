import { n as normalizeComponent } from "./index.js";
import "lodash";
import "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("edit-modal-composer", { attrs: { "title": "Edit Container Type", "has-deactivate-button": false, "has-delete-button": true } }, [_c("text-input", { key: "code", attrs: { "label": "Container Type", "disabled": true, "span": 24 } }), _c("text-input", { key: "description", attrs: { "label": "Description", "disabled": true, "span": 24 } }), _c("text-input", { key: "tareWeight", attrs: { "label": "Tare Weight", "disabled": true, "span": 24 } }), _c("cf-select-input", { key: "autoNumbering", attrs: { "form-item": "", "rules": "required", "label": "Numbering", "placeholder": "Choose a Numbering", "data-source": _vm.arrNumbering, "source": "source", "source-label": "sourceLabel", "span": 24 } }), _c("number-input", { key: "weighsPerContainer", attrs: { "form-item": "", "rules": "required|min_value:1|max_value:4", "label": "Weighs per Container", "placeholder": "Type Weight Per Container", "min": 1, "max": 4, "span": 24 } }), _c("number-input", { key: "holdThreshold", attrs: { "form-item": "", "rules": "min_value:-999.99|max_value:999.99", "label": "Hold Threshold", "placeholder": "0.00", "min": -999.99, "max": 999.99, "span": 24 } })], 1);
};
var staticRenderFns$1 = [];
var EditContainerType_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "EditContainerType",
  data() {
    return {
      arrNumbering: [
        {
          source: "A",
          sourceLabel: "Automatic"
        },
        {
          source: "E",
          sourceLabel: "Entered"
        }
      ]
    };
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "ddb96508", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditContainerType = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-container-type" }, [_c("resource", { attrs: { "name": "cf.container-types", "api-url": _vm.apiUrl, "redirect-route": "/container-filling/container-type" } }, [_c("edit-container-type")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditContainerType
  },
  data() {
    return {
      EditContainerType,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
